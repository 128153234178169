<section>
  <div class="background">
    <!--START HERO-->
    <div class="hero row">
      <!-- <div class="d-flex align-items-start">
        <h2 class="font-title">Welcome to EnableAll</h2>
      </div> -->
      <!-- <div class="d-flex align-items-start justify-content-around col-12 col-md-4" >
        <h1  class="font-title font-bold" style="width: 100%; font-size: 40px ">
          Discover 1000s <br> of products from  <br>  independent UK <br> retailers <span style=" color: #F21C5C;">.</span> <br>
              <button   class="ea-button ea-button-primary mt-5 mb-4"   [routerLink]="['/category/0/', '6628c062b1185d00082cf032']" >
          Shop Clothing and Shoes
        </button>
        </h1> -->


        <div class="d-flex align-items-start justify-content-around col-12 col-md-4 "  >
          <h1  class="font-title font-bold disctop" style="width: 100%; font-size: 48px !important; ">
            Our gift to you - <span style=" color: #F21C5C;">Free</span><br>
             standard delivery on <br>
              all orders over £30! <br>
              <span class="mt-5" style="font-size: 24px;">Hurry, ends 16 Dec.</span> <br> 
              <br> 
                <button   class="ea-button ea-button-primary mt-5 mb-4"   [routerLink]="['/category/0/', '6628c062b1185d00082cf032']" >
            Shop Clothing and Shoes
          </button>
          </h1>
          <h1  class="font-title font-bold mobile-product" style="width: 100%; font-size: 48px !important; ">
            Our gift to you... <br><span style=" color: #F21C5C;">Free</span>
             standard delivery on 
              all<br> orders over £30! <br><br>
              <span class="mt-5" style="font-size: 40px;">Hurry, ends 16 Dec.</span> <br> 
              <br> 
                <button   class="ea-button ea-button-primary mt-5 mb-4"   [routerLink]="['/category/0/', '6628c062b1185d00082cf032']" >
            Shop Clothing and Shoes
          </button>
          </h1>
        <!-- <div class="row gx-2">

          <div class="row gx-2">

            <div class="col-5 col-lg-3">
              <img class="big-img" src="assets/images/home-page/charles.png" alt="Candle" style="width: 100%; cursor: pointer;" [routerLink]="['/product-details/', 'e-100626']">
              <img class="small-img-bag mt-2" src="assets/images/home-page/bag.png" alt="Yellow Bag" style="width: 100%; cursor: pointer;" [routerLink]="['/product-details/', '1848770']">
            </div>
            

            <div class="col-5 col-lg-3">
              <img class="big-img" src="assets/images/home-page/avatar.png" alt="LEGO Avatar" style="width: 100%; cursor: pointer;" [routerLink]="['/product-details/', 'e-103214']">
              <button [routerLink]="['/product-details/', 'e-144212']" style="width: 100%;">
                <img class="small-img-bag mt-2" src="assets/images/home-page/fc25.png" alt="FC25 Game" style="width: 100%;">
              </button>
            </div>
          

          </div>
          
        

        </div> 
         -->

      </div>
      <!-- <div class="row  col-12 col-md-8 disctop ">
        <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/saleScarf.png" alt="Scarf" [routerLink]="['/product-details/', 'e-123189']">
          <img class="product-image mt-3" src="assets/images/home-page/saleLodo.png" alt="Lego" [routerLink]="['/product-details/', 'e-125980']">
        </div>
        <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/saleGame.png" alt="Controller" [routerLink]="['/product-details/', 'e-149700']">
          <img class="product-image mt-3" src="assets/images/home-page/Hoodie.png" alt="Hoodie" [routerLink]="['/product-details/', '1831937']">
        </div>
        <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/Cushion.png" alt="Cushion"  [routerLink]="['/product-details/', 'e-100915']">
          <img class="product-image mt-3" src="assets/images/home-page/book.png" alt="Book" [routerLink]="['/product-details/', 'e-125716']">
        </div>
        <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/Candle.png" alt="Candle"  [routerLink]="['/product-details/', 'e-100626']">
          <img class="product-image mt-3" src="assets/images/home-page/Silk.png" alt="Silk" [routerLink]="['/product-details/', 'e-152773']">
        </div>
       </div> -->

       <!-- <div class="mobile-product mb-4">
        <div style="display: flex;" class="row">
        <div class="col-md-2 col-6">
          <img class="product-image"  src="assets/images/home-page/saleScarf.png" alt="Scarf" [routerLink]="['/product-details/', 'e-123189']">
          <img class="product-image mt-3"  src="assets/images/home-page/saleGame.png" alt="Controller" [routerLink]="['/product-details/', 'e-149700']">
        </div>
        <div class="col-md-2 col-6">
          <img class="product-image"  src="assets/images/home-page/Cushion.png" alt="Cushion"  [routerLink]="['/product-details/', 'e-100915']">

          <img class="product-image mt-3" src="assets/images/home-page/Silk.png" alt="Silk" [routerLink]="['/product-details/', 'e-152773']">
        </div> -->
        <!-- <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/Cushion.png" alt="Cushion"  [routerLink]="['/product-details/', 'e-100915']">
          <img class="product-image mt-3" src="assets/images/home-page/book.png" alt="Book" [routerLink]="['/product-details/', 'e-106153']">
        </div>
        <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/Candle.png" alt="Candle"  [routerLink]="['/product-details/', 'e-100626']">
          <img class="product-image mt-3" src="assets/images/home-page/Silk.png" alt="Silk" [routerLink]="['/product-details/', 'e-152773']">
        </div> -->
      <!-- </div> -->
       <!-- </div> -->
      <div class="row  col-12 col-md-8">
        <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/hand.png" alt="Candle" [routerLink]="['/product-details/', 'e-100925']">
          <img class="product-image mt-3" src="assets/images/home-page/nike.png" alt="Nike sweatshirt" [routerLink]="['/product-details/', '1878960']">
        </div>
        <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/fc.png" alt="FC25 Video Game" [routerLink]="['/product-details/', 'e-144212']">
          <img class="product-image mt-3" src="assets/images/home-page/rescue.png" alt="SOS Skincare" [routerLink]="['/product-details/', 'e-129077']">
        </div>
        <div class="col-md-2 col-3">
          <img class="product-image"  src="assets/images/home-page/jewellery.png" alt="Ring"  [routerLink]="['/product-details/', 'e-149847']">
          <img class="product-image mt-3" src="assets/images/home-page/sunshine.png" alt="Book" [routerLink]="['/product-details/', 'e-126111']">
        </div>
        <div class="col-md-2 col-3">
          <img class="product-image" style="height: 160px;"  src="assets/images/home-page/saleScarf.jpeg" alt="Scarf"  [routerLink]="['/product-details/', 'e-123189']">
          <img class="product-image mt-3" src="assets/images/home-page/lodo.png" alt="Lego set" [routerLink]="['/product-details/', 'e-103444']">
        </div>
       </div>
      <!-- <button   class="ea-button ea-button-primary mobile mt-4 ml-2"[routerLink]="['/category/0/', '6628c062b1185d00082cf032']" >
        Shop Clothing and Shoes
      </button> -->
        <br class="mobile-product">  <br class="mobile-product">  <br class="mobile-product">  <br class="mobile-product">
        <br class="mobile-product">  <br class="mobile-product">  <br class="mobile-product">  <br class="mobile-product">

        <br class="mobile">  <br class="mobile">  
    </div>
    <!--END HERO-->
  </div>
 
  <div class="icons ">
    <div class="about-us-icons">
      <div class="row">
        <!--a11y-->
        <div class="col-sm-6 col-md-4 col-lg-2 col-6" [routerLink]="['/category/0/', '6628c062b1185d00082cf032']" style="cursor: pointer;">
          <div class="icon  border-radius-100"   >
            <img
              ngSrc="assets/images/home-page/clothing.svg"
              alt="Clothing and Shoes"
              width="60"
              height="60"
              data-sizes="auto"
              class="lazyload"
            />
          </div>
          <p class="text-theme-on-background font-bold" style="margin: 0px 10px !important;" >
            Clothing and Shoes        </p>
          </div>
  
        <!--marketplace-->
        <div class="col-sm-6 col-md-4 col-lg-2 col-6"  [routerLink]="['/category/0/', '6628c06bb1185d00082cf042']" style="cursor: pointer;">
          <div class="icon marketplace purple border-radius-100">
            <img
            ngSrc="assets/images/home-page/jewellery.svg"
              alt="Jewellery"
              data-sizes="auto"
              width="60"
              height="60"
              class="lazyload"
            />
          </div>
          <p class="text-theme-on-background font-bold" style="margin: 0px 10px !important;">
            Jewellery        </p>
        </div>
  
        <!--trajectory-->
        <div class="col-sm-6 col-md-4 col-lg-2 col-6" [routerLink]="['/category/0/', '6628c067b1185d00082cf03a']" style="cursor: pointer;">
          <div class="icon trajectory green border-radius-100">
            <img
            ngSrc="assets/images/home-page/home.svg" 
              width="60"
              height="60"
              alt="Home and Garden"
              data-sizes="auto"
              class="lazyload"
            />
          </div>
          <p class="text-theme-on-background font-bold" style="margin: 0px 10px !important;">
            Home and Garden
          </p>
        </div>
  
        <!--paint-->
        <div class="col-sm-6 col-md-4 col-lg-2 col-6"  [routerLink]="['/category/0/', '6628c06fb1185d00082cf04a']" style="cursor: pointer;">
          <div class="icon paint red border-radius-100">
            <img
            ngSrc="assets/images/home-page/toys.svg" 
              alt="Toys"
              data-sizes="auto"
              width="60"
              height="60"
              class="lazyload"
            />
          </div>
          <p class="text-theme-on-background font-bold" style="margin: 0px 10px !important;">
            Toys        </p>
        </div>
  
        <!--group-->
        <div class="col-sm-6 col-md-4 col-lg-2 col-6" [routerLink]="['/category/0/', '6628c04fb1185d00082cf02a']" style="cursor: pointer;">
          <div class="icon group blue border-radius-100"> 
            <img
            ngSrc="assets/images/home-page/books.svg" 
              alt="Books"
              width="60"
              height="60"
              data-sizes="auto"
              class="lazyload"
            />
          </div>
          <p class="text-theme-on-background font-bold" style="margin: 0px 10px !important;">
  Books        </p>
        </div>
  
        <div class="col-sm-6 col-md-4 col-lg-2 col-6"  [routerLink]="['/category/0/', '6628c073b1185d00082cf052']" style="cursor: pointer;">
          <div class="icon group blue border-radius-100"> 
            <img
            ngSrc="assets/images/home-page/game.svg"  
              alt="Video Gaming"
              width="60"
              height="60"
              data-sizes="auto"
              class="lazyload"
            />
          </div>
          <p class="text-theme-on-background font-bold" style="margin: 0px 10px !important;">
            Video Gaming        </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--ABOUT US-->
<!-- <section class="about-us">
  <div class="about-us-header pb-5 text-center">
    <h2 class="font-bold pt-5">Why Use EnableAll?</h2>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="card bg-theme-surface text-theme-on-surface">
        <div class="justify-content-center">
          <div class="icon a11y border-radius-100">
            <img
              ngSrc="assets/images/icons/Group22.svg"
              width="60"
              height="60"
              alt="a white human figure in a pink circle"
              data-sizes="auto"
              class="lazyload p-0"
            />
          </div>
          <div class="content text-center">
            <div class="pt-3 pb-3">
              <h3 class="font-bold">Leading the change</h3>
            </div>

            <p class="text-theme-on-background">
              We ensure nobody is left out, our site is the first AAA accessible
              marketplace.
            </p>
            <div data-sizes="auto" class="lazyload img-tile"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="card bg-theme-surface text-theme-on-surface">
        <div class="justify-content-center">
          <div class="icon marketplace border-radius-100">
            <img
              ngSrc="assets/images/icons/Group26.svg"
              alt="a white house figures on a pink circle"
              width="60"
              height="60"
              data-sizes="auto"
              class="lazyload p-0"
            />
          </div>
          <div class="content text-center">
            <div class="pt-3 pb-3">
              <h3 class="font-bold">Support retailers</h3>
            </div>

            <p class="text-theme-on-background">
              By using EnableAll you are directly supporting retailers that are
              supporting those with disabilities.
            </p>
            <div data-sizes="auto" class="lazyload img-tile second-tile"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--END ABOUT US-->

<!--ICONS-->


<section>
  <div class="row top-categories">
    <button class="col-12 col-md  category mt-2" [routerLink]="['/category/0/', '6628c067b1185d00082cf03a']">
      <img src="assets/images/home-page/homeCategory.jpg" alt="home Category" >
      <div class="category-Text">
        <p class="d-flex justify-content-center">Home and garden essentials</p> 
        <span class="d-flex justify-content-center" style="font-size: 19px !important; color:white;">
          Explore Home and Garden
        </span> 
      </div>
    </button>

    <!-- <button class="col-12 col-md  category mt-2" [routerLink]="['/category/0/', '6628c06fb1185d00082cf04a']" >
      <img src="assets/images/home-page/toy.png" alt="toy category" >
      <div class="category-Text">
        <p class="d-flex justify-content-center">Toys and Games up to 20% off​</p> 
        <span class="d-flex justify-content-center" style="font-size: 19px !important; color:white;">
          Ideal for festive gifting  <mat-icon>keyboard_arrow_right</mat-icon>
   
          </span> 
      </div>
    </button> -->


    <button class="col-12 col-md category mt-2" [routerLink]="['/category/0/', '6628c062b1185d00082cf032']">
      <img src="assets/images/home-page/clothingCategory.png" alt="clothing category" >
      <div class="category-Text">
        <p class="d-flex justify-content-center">Clothes and Shoes up to 60% off​</p> 
        <span class="d-flex justify-content-center" style="font-size: 19px !important; color:white;">
          Huge savings on big brands <mat-icon>keyboard_arrow_right</mat-icon>

        </span> 
      </div>
    </button>
  </div>
  
</section>
<!--END ICONS-->
